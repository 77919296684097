<template>
  <div class="hint-review-page" v-if="initLoading">
    <div class="readable_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
  <page-not-found v-else-if="pageNotFound"></page-not-found>
  <div class="hint-review-page" v-else>
    <div class="readable_content">
      <div class="navigation-row">
        <div class="back-button">
          <router-link :to="backRoute" ref="back">{{ backRouteLabel }}</router-link>
        </div>
        <div v-if="userIsStaffState" class="admin-controls buttons">
          <mcr-button :href="adminUrl" target="_blank" class="mcr-button admin transparent small"
            >Hint admin</mcr-button
          >
        </div>
      </div>
      <div class="title">
        <span class="disclaimer">{{ disclaimerLabel }}</span>
        <span class="person-name">
          <router-link :to="ftPersonRoute" class="name-link"
            ><account-icon :size="20"></account-icon>
            <span class="name">{{ personFullName }}</span>
            <span>{{ personDates }}</span>
          </router-link>
        </span>
      </div>
    </div>

    <div class="comparison-table">
      <stand-out-block>
        <div class="badge-container" v-if="familyTreeHintDetailsState.is_high_quality">
          <div class="chip dark-green-reverse bolder slim text-md" v-if="familyTreeHintDetailsState.is_high_quality">
            <workspace-premium-icon :size="20" />high quality
          </div>
        </div>

        <div class="source-data">
          <router-link :to="mentionRoute" class="details-link">{{ sourceTitle }}</router-link>
          <record-type :type="source.type_display" :type-name="sourceTypeLabel"></record-type>
        </div>

        <div class="images-container" v-if="images && images.length">
          <div class="image-item" @click="onImageClick(image)" v-for="(image, index) in images">
            <img :src="image.thumbnail" class="with-shadow" />
            <div class="overlay" v-if="getImageOverlayText(image, index)">
              <span>{{ getImageOverlayText(image, index) }}</span>
            </div>
          </div>
        </div>

        <div v-if="source.is_external && externalUrl" class="external-link">
          <a :href="externalUrl" target="_blank">
            <span>View record details on partner site</span>
            <link-icon class="link-icon" :size="18"></link-icon>
          </a>
        </div>
      </stand-out-block>

      <div class="table-block">
        <compare-persons-table :rows="comparisonTable" :save-mode="false" ref="table"></compare-persons-table>

        <div class="actions">
          <mcr-button class="white" v-if="canIgnore" @click="ignore" :loading="ignoreLoading" :disabled="acceptLoading"
            >Ignore</mcr-button
          >
          <mcr-button class="accept" :loading="acceptLoading" :disabled="ignoreLoading" @click="acceptHintClick"
            >Accept Record</mcr-button
          >
        </div>
      </div>
    </div>

    <mentions-images-gallery-overlay-container
      :getNextPage="mentionsImagesGetNextPage"
    ></mentions-images-gallery-overlay-container>
    <save-data-modal
      :person="person"
      :family-tree-id="familyTreeId"
      :comparison-table="saveTable"
      :relatives-table="relativesTable"
      :person-relatives="personRelatives"
      @save="saveInformationClick"
    ></save-data-modal>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import RecordType from '@common/elements/icons/RecordType';
import WorkspacePremiumIcon from '@common/elements/icons/WorkspacePremiumIcon';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import MentionsImagesGalleryOverlayContainer from '@common/pages/personRecords/MentionsImagesGalleryOverlayContainer';
import personRecordsPage from '@common/pages/personRecords/js/person-records-page';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getLifetimeYears} from '@common/utils/utils';
import {getHintAdminUrl} from '@common/utils/utils.admin';
import {getFullNameCn, getFullNameEn} from '@common/utils/utils.names';
import {getPersonRecordDetailRoute} from '@common/utils/utils.routes';
import isEmpty from 'lodash/isEmpty';
import AccountIcon from 'vue-material-design-icons/Account';
import LinkIcon from 'vue-material-design-icons/OpenInNew';
import {mapGetters} from 'vuex';

import ComparePersonsTable from '@/components/common/tree/ComparePersonsTable';
import {
  createComparisonTable,
  createRelativesTable,
  getRecordTitle,
} from '@/components/common/tree/utils.personsCompare';

import {UNKNOWN_NAME} from '@/components/modules/familyTree/constants';
import PageNotFound from '@/components/page.not.found';

import SaveDataModal from './SaveDataModal';

export default {
  metaInfo() {
    const name = this.matchPerson.first_names || this.matchPerson.surnames ? this.fullNameEn || this.fullNameCn : '';
    const reviewTitle = name ? `Review Record - ${name}` : 'Review Record';
    return {
      title: reviewTitle,
    };
  },
  created() {
    if (this.familyTreeHintDetailsState && this.familyTreeHintDetailsState.id === this.$route.params.hintId) {
      return this.initTable(this.familyTreeHintDetailsState);
    }
    this.initLoading = true;
    this.$store
      .dispatch('fetchFamilyTreeHintDetailsAction', this.$route.params.hintId)
      .then(res => {
        this.$store.dispatch('getPersonMentionsImagesAction', {
          code: this.matchPerson.code,
          sourceId: this.source.id,
          forHint: true,
        });
        this.initTable(res);
      })
      .catch(() => {
        this.pageNotFound = true;
      })
      .finally(() => {
        this.initLoading = false;
      });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name && (from.name === 'familytree-hints' || from.name === 'familytree-profile-details')) {
        vm.$store.commit('setFamilyTreeHintDetailsPreviousRouteState', {...from});
      }
      if (!isEmpty(vm.$store.getters.familyTreeHintDetailsPreviousRouteState)) {
        vm.previousPageRoute = {...vm.$store.getters.familyTreeHintDetailsPreviousRouteState};
      }
    });
  },
  data() {
    return {
      fieldsToSave: [],
      comparisonTable: [],
      saveTable: [],
      relativesTable: [],
      personRelatives: [],
      previousPageRoute: null,
      pageNotFound: false,
      initLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'familyTreeHintDetailsState',
      'familyTreeHintDetailsLoadingState',
      'familyTreeHintIgnoreLoading',
      'familyTreeHintAcceptLoading',
      'userIsStaffState',
    ]),
    ftPersonRoute() {
      return {
        name: 'familytree-profile-details',
        params: {id: this.familyTreeId, personId: this.person.object_id},
        query: {tab: 'details'},
      };
    },
    adminUrl() {
      return getHintAdminUrl(this.familyTreeHintDetailsState.id);
    },
    person() {
      return this.familyTreeHintDetailsState.person || {};
    },
    matchPerson() {
      return this.familyTreeHintDetailsState.match_person || {};
    },
    source() {
      return this.familyTreeHintDetailsState.source || {};
    },
    externalUrl() {
      return this.familyTreeHintDetailsState.external_source_url;
    },
    familyTreeId() {
      return this.familyTreeHintDetailsState.family_tree_id;
    },
    status() {
      return this.familyTreeHintDetailsState.status;
    },
    mentionRoute() {
      return getPersonRecordDetailRoute(this.matchPerson.code);
    },
    backRoute() {
      return this.previousPageRoute || {...this.ftPersonRoute, query: {tab: 'hints'}};
    },
    backRouteLabel() {
      const mapping = {
        'familytree-hints': 'Back to RecordFinder™',
        'familytree-profile-details': 'Back to Person Profile',
      };
      return mapping[this.backRoute.name] || 'Back';
    },
    disclaimerLabel() {
      return 'Confirm that this record matches';
    },
    personFullName() {
      const firstNames = this.person.first_names || [];
      const surnames = this.person.surnames || [];
      return getFullNameEn(firstNames, surnames) || getFullNameCn(firstNames, surnames) || UNKNOWN_NAME;
    },
    personDates() {
      const years = getLifetimeYears(this.person.birth_date, this.person.death_date, this.person.is_deceased);
      return years ? `(${years})` : '';
    },
    fullNameEn() {
      return getFullNameEn(this.matchPerson.first_names, this.matchPerson.surnames);
    },
    fullNameCn() {
      return getFullNameCn(this.matchPerson.first_names, this.matchPerson.surnames);
    },
    sourceTitle() {
      return getRecordTitle(this.fullNameEn, this.fullNameCn, this.source);
    },
    sourceTypeLabel() {
      const country = this.source.place && this.source.place.pinyin;
      const type = this.source.type_display;
      return country && type ? `${country}, ${type}` : country || type;
    },
    images() {
      return this.familyTreeHintDetailsState.images;
    },
    fullImages() {
      return this.fullImagesLoaded ? this.$store.getters.personMentionsImagesState : [];
    },
    fullImagesMeta() {
      return this.fullImagesLoaded ? this.$store.getters.personMentionsImagesMetaState : {};
    },
    fullImagesLoaded() {
      return this.$store.getters.personMentionsImagesMetaState.source_id === this.source.id;
    },
    ignoreLoading() {
      return this.familyTreeHintIgnoreLoading[this.familyTreeHintDetailsState.id];
    },
    acceptLoading() {
      return (
        this.familyTreeHintAcceptLoading[this.familyTreeHintDetailsState.id] || this.familyTreeHintDetailsLoadingState
      );
    },
    canIgnore() {
      return this.status !== 'accepted';
    },
  },
  methods: {
    initTable(hint) {
      this.comparisonTable = createComparisonTable(hint.person, hint.match_person, hint.mention_id);
      this.saveTable = this.comparisonTable.filter(item => item.canSave);
      this.fieldsToSave = this.saveTable.filter(item => item.preselected).map(item => item.fieldName);

      this.personRelatives = hint.person.relatives;
      this.relativesTable = createRelativesTable(hint.person.relatives, hint.match_person.relatives);
    },
    goBack() {
      this.$router.push(this.backRoute);
    },
    getImageOverlayText(image, index) {
      const imagesLength = this.images.length;
      const totalCount = this.fullImagesMeta.total_count;
      const moreImagesCount = totalCount ? totalCount - imagesLength : 0;
      if (moreImagesCount && index === imagesLength - 1) {
        return `+ ${moreImagesCount + 1} Images`;
      }
    },
    getNewValues(item) {
      return item.newValuesMethod ? item.newValuesMethod(item.personValue, item.matchValue) : item.matchValue;
    },
    async onImageClick(image) {
      const sourceId = this.source.id;
      const data = this.fullImages;
      const meta = this.fullImagesMeta;

      const sourceName = `${this.source.title_en || ''} ${this.source.title_ch || ''}`.trim();

      const galleryMeta = {
        personName: `${this.fullNameEn} ${this.fullNameCn}`,
        totalCount: meta.total_count,
        sourceName: sourceName,
        getViewerRoute: (priority, id) => {
          const query = {page: priority, page_id: id};
          return {name: 'source-viewer', params: {sourceId}, query};
        },
      };
      this.$store.commit('setGalleryMetaState', galleryMeta);
      this.$store.commit('setGalleryItemsState', this.mentionsImagesGetGalleryData(data, meta));
      this.$store.dispatch('displayGalleryAction', image.id);
    },
    mentionsImagesGetGalleryData(items, meta) {
      let data = items.map(item => ({...item, _id: item.id, src: item.url}));
      if (data.length < meta.total_count) {
        data.push({_id: 'loading', isLoading: true});
      }
      return data;
    },
    toggleCheckbox(item) {
      if (!item.canSave) {
        return;
      }
      const index = this.fieldsToSave.indexOf(item.fieldName);
      if (index >= 0) {
        this.fieldsToSave.splice(index, 1);
      } else {
        this.fieldsToSave.push(item.fieldName);
      }
    },
    ignore() {
      if (this.status === 'ignored') {
        this.goBack();
        return;
      }
      AnalyticsAmplitudeHandler.trackHintClickIgnoreEvent();
      this.$store
        .dispatch('ignoreFamilyTreeHintAction', {id: this.familyTreeHintDetailsState.id, ft_id: this.familyTreeId})
        .then(() => {
          this.$store.commit('setFamilyTreeHintsListState', null);
          this.goBack();

          this.$nextTick(() => {
            this.$store.commit('setFamilyTreeHintDetailsState', null);
          });
        })
        .catch(err => {
          this.$toasted.error('Error ignoring the record match. Please try again later.');
        });
    },
    async acceptHintClick() {
      AnalyticsAmplitudeHandler.trackHintClickAcceptEvent();
      const data = await this.$store.dispatch('fetchFamilyTreeHintDetailsAction', this.$route.params.hintId);
      this.initTable(data);

      this.$nextTick(async () => {
        if (this.saveTable.length === 0 && this.relativesTable.length === 0) {
          await this.saveInformation({});
          this.goBack();
          return;
        }
        this.$modal.show('save-data-modal');
      });
    },
    saveInformationClick({personData, relativesData}) {
      AnalyticsAmplitudeHandler.trackHintClickSaveInfoEvent();
      const hintPromise = this.saveInformation(personData);
      const relativesPromise = this.saveRelatives(relativesData);
      Promise.all([hintPromise, relativesPromise])
        .then(responses => {
          this.goBack();

          this.$nextTick(() => {
            this.$store.commit('setFamilyTreeHintDetailsState', null);
          });
        })
        .catch(err => {
          this.$toasted.error('Error accepting the record match. Please try again later.');
        });
    },
    saveInformation(newData) {
      return this.$store
        .dispatch('acceptFamilyTreeHintAction', {
          id: this.familyTreeHintDetailsState.id,
          data: newData,
          ft_id: this.familyTreeId,
        })
        .then(() => {
          this.$store.commit('setFamilyTreeHintsListState', null);
          this.$store.commit('setFamilyTreePersonState', null);
        });
    },
    saveRelatives(relativesData) {
      if (isEmpty(relativesData)) {
        return Promise.resolve();
      }
      return this.$store.dispatch('importRelativesToFamilyTreePerson', {
        id: this.person.object_id,
        new_relatives: relativesData,
        from_hint: true,
      });
    },
    mentionsImagesGetNextPage() {
      personRecordsPage.mentionsImagesGetNextPage(this);
    },
  },
  components: {
    ComparePersonsTable,
    PageNotFound,
    McrButton,
    StandOutBlock,
    AccountIcon,
    RecordType,
    LinkIcon,
    MentionsImagesGalleryOverlayContainer,
    SaveDataModal,
    WorkspacePremiumIcon,
  },
  name: 'HintReviewPage',
};
</script>

<style lang="scss" scoped>
.hint-review-page {
  padding-bottom: 50px;

  .readable_content {
    padding-bottom: 0;
  }

  .navigation-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 16px;
    border-bottom: 1px solid $divider-line-light;

    .back-button {
      a:before {
        content: '\2039';
        margin-right: 5px;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: bold;
    padding: 30px 0;

    .disclaimer {
      margin-right: 5px;
    }

    .person-name {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      .name-link {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .name {
          margin: 0 5px;
        }
      }
    }
  }

  .table-block {
    border-radius: 4px;
    box-shadow: $box-shadow-extra-light;
    overflow: hidden;
  }
  .comparison-table {
    max-width: $reading-max-width;
    margin: 0 auto;

    .stand-out-block {
      margin-bottom: 30px;
    }

    .badge-container {
      display: flex;
      margin-bottom: 8px;
      column-gap: 5px;
    }

    .record-type {
      margin-top: 10px;
    }
    .source-data {
      .details-link {
        font-weight: bold;
        word-break: break-word;
      }
    }

    .external-link {
      display: flex;
      a {
        display: flex;
        align-items: center;
      }
      .link-icon {
        margin-left: 4px;
      }
    }

    .image,
    .external-link {
      margin-top: 15px;
    }

    .image {
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
        cursor: pointer;
      }
    }

    &::v-deep {
      .table-row {
        display: flex;

        .values {
          display: flex;
          flex-grow: 1;
          flex-basis: 0;
        }
      }

      .table-cell {
        padding: 19px;
        flex-grow: 1;
        flex-basis: 0;

        .input-wrapper {
          padding-right: 0;
        }
      }
    }
  }

  .images-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
    margin: 30px 0;
    max-width: 480px;
    .image-item {
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        display: block;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      opacity: 0.8;
      z-index: 1;
      top: 0;
      left: 0;
    }
  }

  .actions {
    display: flex;
    padding: 30px;
    justify-content: flex-end;
    background-color: white;

    .white {
      max-width: 180px;
      min-width: 80px;
      margin-right: 20px;
      &::v-deep .bounce-loading > div {
        background-color: $mcr-pale-red;
      }
    }
    .accept {
      min-width: 162px;
    }
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding-bottom: 0;

    .title {
      flex-direction: column;
      align-items: self-start;
      .person-name .account-icon {
        margin-left: 0;
      }
    }

    .comparison-table {
      .image {
        img {
          width: 100%;
          margin: auto;
        }
      }

      &::v-deep {
        .table-legend .label {
          display: none;
        }

        .table-row {
          padding: 0;
          flex-direction: column;
        }

        .table-cell {
          &:first-of-type {
            padding-left: 40px;
          }

          &.label {
            max-width: 100%;
            background: $neutral-100;
            padding: 10px 10px 10px 40px;
          }

          .input-wrapper {
            display: none;
          }
        }

        .table-body .values .table-cell:first-of-type {
          padding-left: 12px;
        }
      }
    }

    .actions {
      justify-content: stretch;
      > * {
        flex-grow: 1;
      }
    }
  }
}
</style>
